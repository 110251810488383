<div class="
    drop
    h-100
    d-flex
    flex-column
    justify-content-center
    align-items-center
  ">
  <div class="text-center">
    <h3>
      @if (title) {
        <div>{{ title }}</div>
      }
      @if (message) {
        <small> {{ message }}</small>
      }
    </h3>
  </div>

  @if (icon) {
    <div class="row">
      <div class="col-12 text-center">
        <h1 class="pt-4 pb-3">
          <span class="{{ icon }} text-primary"></span>
        </h1>
      </div>
    </div>
  }
</div>
