import { Injectable, Injector } from "@angular/core";
import { HttpRequest, HttpHeaders, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class HttpClientInterceptorService implements HttpInterceptor {
  constructor(
    private injector: Injector,
  ) { }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const headerSettings = this.getHeaderSettings(req.headers);

    req = req.clone({
      setHeaders: headerSettings,
      headers: new HttpHeaders({
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "Sat, 01 Jan 2000 00:00:00 GMT"
      })
    });

    return next.handle(req);
  }

  private getHeaderSettings(headers: HttpHeaders): { [name: string]: string | string[]; } {
    const headerSettings: { [name: string]: string | string[] } = {};
    for (const key of headers.keys()) {
      const settings = headers.getAll(key);
      if (settings?.length) {
        headerSettings[key] = settings;
      }
    }
    return headerSettings;
  }
}
