import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Input } from "@angular/core";
import { ReportsService } from "app/services/reports.service";
import { ManufacturerFilterService } from "app/services/filters/manufacturer-filter.service";
import { AuthorizationService } from "app/services/auth/authorization.service";
import { firstValueFrom, Subscription } from "rxjs";
import { PBIReportService } from "app/services/pbireport.service";
import { SignalRService, SignalRMessage, SignalRReportList } from "app/services/signalr.service";
import { HeaderNotificationService } from "app/services/header-notification.service";
import { ExportToFileData } from "app/models/exportToFileData";
import { ReportConfig } from "app/models/reportFilters";
import { EplanAnalyticsService } from "app/services/eplananalytics.service";
import { EplanAnalyticsEvents } from "app/models/eplanAnalyticsEvents";
import { EplanAnalyticsUserDownloadedReportEvent } from "app/models/eplanAnalyticsUserDownloadedReportEvent";
import { TranslateService } from "@ngx-translate/core";
import { Blade } from "app/models/blade";
import { PBIReportResolverService } from "app/services/pbireportresolver.service";
import { LoggerService } from "app/services/logger.service";
import { UserIdleService } from "app/services/user-idle.service";
import { filter, take } from "rxjs/operators";
import * as pbi from "powerbi-client";
import { QueryService } from "app/services/query.service";
import { ePLANOrgs } from "app/models/eplanReportCode";

const REPORT_LIST_ENTRY_UPDATED = "reportupdated";
const REPORT_LIST_ENTRY_DELETED = "reportdeleted";
const EXPORT_TIMEOUT_MS = 310000;

@Component({
  selector: "app-reportviewer",
  templateUrl: "./reportviewer.component.html",
  styleUrls: ["./reportviewer.component.scss"],
  providers: [
    PBIReportService
  ]
})
export class ReportViewerComponent implements OnInit, OnDestroy {
  @ViewChild("reportContainer", { static: false }) private reportContainer?: ElementRef;

  @Input() set blade(value: Blade) {
    this.pbiReportResolverService.set(value, this.pbiReportService);
  }

  @Input() set reportConfig(config: ReportConfig | null) {
    if (config) {
      const lastReportConfig: ReportConfig | null = this.pbiReportService.getReportConfig();
      this.pbiReportService.setReportConfig(config);
      const sameReport = lastReportConfig
        && (lastReportConfig.code === config.code)
        && (lastReportConfig.category === config.category)
        && (lastReportConfig.page === config.page);

      if (!lastReportConfig || !sameReport || config?.visuals?.reset) {
        this.pbiReportService.hideReport();
        this.authService.isUserAuthorized$.pipe(take(1)).subscribe(isUserAuthorized => {
          if (isUserAuthorized) {
            this.loadReportWhenToken = false;
            if (config?.visuals?.reset) {
              config.visuals.reset = false;
            }
            this.loadReport(config);
          } else {
            this.loadReportWhenToken = true;
          }
        });
      } else {
        this.pbiReportService.setReportFilters(config.filters);
      }
    }
  }
  get reportConfig(): ReportConfig | null {
    const report = this.pbiReportService.getReportConfig();
    return report ? report : null;
  }

  private reportId?: string;
  private exportId?: string;
  private reportManCode?: string;
  private exportTimerId: null | ReturnType<typeof setTimeout> = null;
  private subscriptions = new Subscription();
  private reportSubscription?: Subscription;
  private reportExportSubscription?: Subscription;
  private eplanAnalyticsSubscription?: Subscription;
  private checkReportExportSubscription?: Subscription;
  private exportToFileSubscription?: Subscription;
  private messagesSubscription?: Subscription;
  private loadReportWhenToken: boolean = false;
  private filterConditionOperators: Map<string, string> = new Map<string, string>([
    ["lessthan", "lt"],
    ["lessthanorequal", "le"],
    ["greaterthan", "gt"],
    ["greaterthanorequal", "ge"],
    ["is", "eq"],
    ["isnot", "ne"],
    ["isblank", "eq"],
    ["isnotblank", "ne"],
    ["startswith", "sw"]
  ]);

  public cssCode?: string;

  constructor(
    private reportsService: ReportsService,
    private authService: AuthorizationService,
    private pbiReportService: PBIReportService,
    private signalRService: SignalRService,
    private notificationService: HeaderNotificationService,
    private translateService: TranslateService,
    private eplanAnalyticsService: EplanAnalyticsService,
    private pbiReportResolverService: PBIReportResolverService,
    private manufacturerFilterService: ManufacturerFilterService,
    private loggerService: LoggerService,
    private userIdleService: UserIdleService,
    private queryService: QueryService
  ) { }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.messagesSubscription?.unsubscribe();
    this.unsubscribeReport();
    this.reportExportSubscription?.unsubscribe();
    this.eplanAnalyticsSubscription?.unsubscribe();
    this.checkReportExportSubscription?.unsubscribe();
    this.exportToFileSubscription?.unsubscribe();
  }

  public ngOnInit(): void {
    this.subscriptions.add(this.translateService.onLangChange.subscribe(() => {
      const report = this.pbiReportService.getReportConfig();
      if (report) {
        this.loadReport(report);
      }
    }));

    this.subscriptions.add(this.pbiReportService.reportRequestor$.subscribe(
      reportRequest => {
        if (reportRequest) {
          this.exportFile(reportRequest.extension);
        }
      }));

    this.subscriptions.add(this.authService.isUserAuthorized$.pipe(
      filter(isUserAuthorized => isUserAuthorized)
    ).subscribe(() => {
      this.initializeData();
    }));

    this.subscriptions.add(this.userIdleService.refreshHandleActivity$.subscribe(action => {
      if (action) {
        this.pbiReportService.handleActivity();
      }
    }));
  }

  private clearExportTimer(): void {
    if (this.exportTimerId !== null) {
      clearTimeout(this.exportTimerId);
      this.exportTimerId = null;
    }
  }

  private notifyExportToFileError(status?: string) {
    if (status) {
      this.notificationService.error(Date.now().toString(),
        this.translateService.instant("StdReport." + status));
    } else {
      this.notificationService.error(Date.now().toString(),
        this.translateService.instant("StdReport.exportToFileFailed"));
    }
    this.exportId = undefined;
    this.pbiReportService.setExportInProgress(false);
  }

  private saveAs(content: Blob, fileName: string, contentType: string) {
    const a = document.createElement("a");
    const file = new Blob([content], { type: contentType });
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
  }

  private saveReport(exportToFile: ExportToFileData) {
    this.reportExportSubscription = this.reportsService.getExportedFile(
      exportToFile.reportId, exportToFile.exportId).pipe(take(1)).subscribe({
        next: exportedFile => {
          if (exportedFile) {
            if (this.reportManCode?.length === 0) {
              const reportConfig = this.pbiReportService.getReportConfig();
              if (reportConfig) {
                this.reportManCode = reportConfig.code;
              } else {
                this.notifyExportToFileError();
              }
            }
            const baseFilename = this.reportManCode?.length ? this.reportManCode : "Report";
            this.saveAs(exportedFile, `${baseFilename}.${exportToFile.exportedFileExtension.toLowerCase()}`, exportedFile.type);
            this.exportId = undefined;
            this.pbiReportService.setExportInProgress(false);
            this.notificationService.success(Date.now().toString(),
              this.translateService.instant("StdReport.exportToFileReady"));
            this.sendUserDownloadedReportEvent(exportToFile.exportedFileExtension);
          } else {
            this.notifyExportToFileError();
          }
        },
        error: this.notifyExportToFileError
      });
  }

  private signalRHandler(message: SignalRMessage) {
    if (message.addressee === "reportlist") {
      const reportListMessage = message.payload as SignalRReportList;

      if (this.reportId !== undefined && this.reportId === reportListMessage.id) {
        if (reportListMessage.kind === REPORT_LIST_ENTRY_UPDATED) {
          this.notificationService.success(Date.now().toString(),
            this.translateService.instant("StdReport.thisReportHasBeenUpdated"));
        } else {
          if (reportListMessage.kind === REPORT_LIST_ENTRY_DELETED) {
            this.notificationService.info(Date.now().toString(),
              this.translateService.instant("StdReport.thisReportHasBeenRemoved"));
          }
        }
      }
    } else if (message.addressee === "exportToFile") {
      const exportToFile = message.payload as ExportToFileData;

      if (exportToFile) {
        if (exportToFile.exportId === this.exportId) {
          this.clearExportTimer();

          if (exportToFile.status === "Succeeded") {
            this.saveReport(exportToFile);
          } else {
            this.notifyExportToFileError(exportToFile.status);
          }
        }
      } else {
        this.notifyExportToFileError();
      }
    }
  }

  private initializeData(): void {
    if (!this.messagesSubscription) {
      this.messagesSubscription = this.signalRService.messages$.subscribe(message => this.signalRHandler(message));
    }

    if (this.loadReportWhenToken) {
      const raport = this.pbiReportService.getReportConfig();
      if (raport) {
        this.loadReport(raport);
      }
    }
  }

  private sendUserDownloadedReportEvent(fileExtension: string): void {
    const eventData: EplanAnalyticsUserDownloadedReportEvent = {
      reportType: fileExtension.toUpperCase()
    };

    this.eplanAnalyticsSubscription = this.eplanAnalyticsService.sendEplanAnalyticsEvent(
      EplanAnalyticsEvents.USER_DOWNLOADED_REPORT_EVENT, eventData).pipe(take(1)).subscribe();
  }

  private unsubscribeReport(): void {
    if (this.reportSubscription) {
      this.reportSubscription.unsubscribe();
      this.reportSubscription = undefined;
    }
  }

  private loadReport(reportConfig: ReportConfig): void {
    this.unsubscribeReport();
    if (reportConfig) {
      if (reportConfig.code) {
        this.cssCode = this.reportsService.codeToCSS(reportConfig.code);
      }
      this.pbiReportService.bootstrap(this.reportId, this.reportContainer);
      this.reportSubscription = this.reportsService.getAvailableReports(
        reportConfig.category, true, false, reportConfig.code).pipe(take(1)).subscribe(reportsList => {
          if (reportsList) {
            if (reportsList.reports.length > 0) {
              this.reportId = reportsList.reports[0].id;
              this.pbiReportService.showReport(this.reportId, this.reportContainer);
            }

            if (reportsList.inactiveReportsCount > 0) {
              const manufacturerName = this.manufacturerFilterService.getManufacturerName(reportConfig.code);
              if (manufacturerName) {
                this.notificationService.info(Date.now().toString(),
                  this.translateService.instant("StdReport.manReportsNotReady"));
              }
            }
          }

        });
    }
  }

  private escapeFilterValue(isDate: boolean, value: string | number | boolean | Date): string {

    if (typeof (value) === "string") {
      if (isDate) {
        const CONVERT_MIN_TO_MILLISECONDS = 60000;
        const date = new Date(Date.parse(value));
        const timezoneOffset = date.getTimezoneOffset() * CONVERT_MIN_TO_MILLISECONDS;
        const timeUTC = date.getTime() - timezoneOffset;
        const dateUTC = new Date(timeUTC);
        const dateValue = dateUTC.toISOString().slice(0, -5);
        return "datetime'" + dateValue + "'";
      }

      return "'" + value + "'";
    }

    return "" + value;
  }

  private joinFilterValues(values: (string | number | boolean)[]): string {
    let result: string = "";
    values.forEach(value => {
      if (result.length > 0) {
        result += ",";
      }

      result += this.escapeFilterValue(false, value);
    });

    return result;
  }

  private convertBasicFilter(pbiFilter: pbi.models.IBasicFilter): string | undefined {
    if (pbiFilter.operator.toLowerCase() !== "in") {
      this.loggerService.Error("Unsupported operator: ", pbiFilter.operator);
      return undefined;
    }

    const target = pbiFilter.target as pbi.models.IColumnTarget;
    let response = target.table + "/" + target.column + " in (";

    response += this.joinFilterValues(pbiFilter.values) + ")";

    return response;
  }

  /* eslint-disable complexity */
  async convertAdvancedFilter(pbiFilter: pbi.models.IAdvancedFilter): Promise<string | undefined> {
    if (pbiFilter.conditions && pbiFilter.conditions.length > 1 &&
      (pbiFilter.logicalOperator.toLowerCase() !== "and" && pbiFilter.logicalOperator.toLocaleLowerCase() !== "or")) {
      this.loggerService.Error("Unsupported logical operator: ", pbiFilter.logicalOperator);
      return undefined;
    }

    let response = "";
    const target = pbiFilter.target as pbi.models.IColumnTarget;
    if (pbiFilter.logicalOperator.toLocaleLowerCase() === "or") {
      response += target.table + "/" + target.column + " in (";
    }
    if (pbiFilter.conditions) {
      for (const condition of pbiFilter.conditions) {
        if (!condition.value) { continue; }
        if (!this.filterConditionOperators.has(condition.operator.toLowerCase())) {
          this.loggerService.Error("Unsupported condition operator: ", condition.operator);
          return undefined;
        }
        if (pbiFilter.logicalOperator.toLocaleLowerCase() !== "or") {
          const conditionOperator = this.filterConditionOperators.get(condition.operator.toLowerCase());
          response += (response.length > 0 ? " and " : "") + target.table + "/" + target.column + " " + conditionOperator + " " +
            this.escapeFilterValue(target.column === "Date", condition.value);
        } else {
          response += response.indexOf("(") === response.length - 1 ? "" : ",";
          if (condition.operator.toLocaleLowerCase() === "startswith") {
            const list: string[] = [];
            const productGroupsIds = await this.getProductGroupsIds(list, condition.value.toString());
            response += response.indexOf("(") - 1 === 0 ? "" : "'" + productGroupsIds.join("','") + "'";
          } else {
            response += this.escapeFilterValue(target.column === "Date", condition.value);
          }
        }
      }
    }

    if (pbiFilter.logicalOperator.toLocaleLowerCase() === "or") {
      response += ")";
    }
    return response;
  }

  async convertFilters(pbiFilters: pbi.models.IFilter[]): Promise<string> {
    let response: string = "";

    for (const pbiFilter of pbiFilters) {
      switch (pbiFilter.filterType) {
        case pbi.models.FilterType.Basic: {
          const basicFilter = this.convertBasicFilter(pbiFilter as pbi.models.IBasicFilter);
          if (basicFilter && basicFilter.length > 0) {
            if (response.length > 0) {
              response += " and ";
            }
            response += basicFilter;
          }
          break;
        }

        case pbi.models.FilterType.Advanced: {
          const advancedFilter = await this.convertAdvancedFilter(pbiFilter as pbi.models.IAdvancedFilter);
          if (advancedFilter && advancedFilter.length > 0) {
            if (response.length > 0) {
              response += " and ";
            }
            response += advancedFilter;
          }
          break;
        }

        default: {
          this.loggerService.Error("Unsupported filter type: ", pbiFilter.filterType);
        }
      }
    }
    return response;
  }

  private checkExportResult() {
    if (!this.reportId || !this.exportId) { return; }
    this.checkReportExportSubscription = this.reportsService.getExportStatus(
      this.reportId, this.exportId).pipe(take(1)).subscribe({
        next: (exportToFileData: ExportToFileData) => {
          if (exportToFileData) {
            if (exportToFileData.exportId === this.exportId) {
              switch (exportToFileData.status) {
                case "Succeeded": {
                  this.saveReport(exportToFileData);
                  break;
                }
                case "Running": {
                  const TIMEOUT_MS = 20000;
                  setTimeout(this.checkExportResult.bind(this), TIMEOUT_MS);
                  break;
                }
                default: {
                  this.notifyExportToFileError(exportToFileData.status);
                }
              }
            }
          } else {
            this.notifyExportToFileError();
          }
        },
        error: () => this.notifyExportToFileError()
      });
  }

  private numberToStringWithPadding(n: number): string {
    const MAX_LEADING_ZEROS = 3;
    return n.toString().padStart(MAX_LEADING_ZEROS, "0");
  }

  async getProductGroupsIds(query: string[], ptg: string): Promise<string[]> {
    const result = query;
    let other;
    const productGroups = await firstValueFrom(this.queryService.getProductGroups("en_US", [ptg]));

    if (!productGroups) { return []; }

    for (let idx = 0; idx < productGroups.length; idx++) {
      other = productGroups[idx];
      if (other?.ptgID && other?.pgID) {
        result.push(this.numberToStringWithPadding(Number(other.ptgID)) + this.numberToStringWithPadding(Number(other.pgID)));
      }
    }
    return result;
  }

  private setReportManufacturerCode(reportConfig: ReportConfig) {
    if (reportConfig) {
      this.reportManCode = reportConfig.code;
      if (ePLANOrgs.includes(reportConfig.code)) {
        const manCode = this.pbiReportService.getManufacturerFilterCode();
        if (manCode && manCode?.length > 0) {
          this.reportManCode = manCode;
        }
      }
    }
  }

  public async exportFile(fileFormat: string) {
    if (!this.pbiReportService.isExportInProgress() && !this.exportId) {
      this.clearExportTimer();

      const params = new Map<string, string>();
      const lastSectionPageName = this.pbiReportService.getLastPageSectionName();
      if (lastSectionPageName) {
        params.set("page-name", lastSectionPageName);
      }

      let bookmarkMetadata = this.pbiReportService.getCurrentStateBookmarkMetadata();
      if (bookmarkMetadata && bookmarkMetadata.state) {
        params.set("bookmark-state", bookmarkMetadata.state);
      } else {
        bookmarkMetadata = this.pbiReportService.getLastBookmarkMetadata();
        if (bookmarkMetadata) {
          params.set("bookmark-name", bookmarkMetadata.name);
        }
      }

      const signalRConnected = this.signalRService.isConnected();
      params.set("signalr-connected", signalRConnected ? "1" : "0");
      await this.setExportFilters(params);

      if (!this.reportId) { return; }
      this.exportToFileSubscription = this.reportsService.postExportToFileData(
        this.reportId, fileFormat, params).pipe(take(1)).subscribe({
          next: (exportToFileData: ExportToFileData) => {
            this.pbiReportService.setExportInProgress(true);

            if (exportToFileData) {
              const exportToFileSucceeded = exportToFileData.status === "Succeeded";
              const exportToFileFailed = exportToFileData.status === "Failed" || exportToFileData.status === "FailedReportNotFound"
                || exportToFileData.status === "FailedStatusUnknown" || exportToFileData.status === "FailedTimedOut"
                || exportToFileData.status === "FailedExportsLimitReached";

              if (exportToFileFailed) {
                this.notifyExportToFileError();
              } else if (exportToFileSucceeded) {
                this.saveReport(exportToFileData);
              } else {
                const report = this.pbiReportService.getReportConfig();
                if (!report) { return; }
                this.setReportManufacturerCode(report);
                this.exportId = exportToFileData.exportId;
                this.notificationService.success(Date.now().toString(),
                  this.translateService.instant("StdReport.exportToFileStarted"));

                if (signalRConnected) {
                  this.exportTimerId = setTimeout(() => {
                    this.notifyExportToFileError("FailedTimedOut");
                  }, EXPORT_TIMEOUT_MS);
                } else {
                  this.checkExportResult();
                }
              }
            } else {
              this.notifyExportToFileError();
            }
          },
          error: this.notifyExportToFileError
        });
    } else {
      this.notificationService.info(Date.now().toString(),
        this.translateService.instant("StdReport.exportToFileNotFinished"));
    }
  }

  private async setExportFilters(params: Map<string, string>) {
    const filters = this.pbiReportService.getFilters();
    if (filters) {
      const reportFilters = await this.convertFilters(filters);
      if (reportFilters && reportFilters.length > 0) {

        params.set("report-filters", reportFilters);
      }
    }
  }
}
