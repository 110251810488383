import { Component, OnInit, OnDestroy, AfterViewInit, HostListener } from "@angular/core";
import { Meta } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "@eplan/language";
import { Menu } from "@eplan/oneheader";
import { getHeaderState } from "@eplan/flux";
import { FeaturesService } from "app/services/features.service";
import { AuthorizationService } from "app/services/auth/authorization.service";
import { filter, map, mergeMap, Observable, Subscription } from "rxjs";
import { HostService } from "app/services/host.service";
import { environment } from "environments/environment";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Blade } from "app/models/blade";
import { ActiveBladeService } from "app/services/blade/active-blade.service";
import { UserIdleService } from "app/services/user-idle.service";
import { forkJoin, switchMap } from "rxjs"
import { take, tap } from "rxjs/operators";
import { UrlService } from "app/services/url.service";
import { Inject } from "@angular/core";
import { CS11_APP_ENVIRONMENT_EXTERNAL } from "app/services/injection-tokens";
import { Store } from "@ngrx/store";
import { ApplicationInsightsService } from "@eplan/flux/analytics";
import { ExternalUserService } from "app/services/external-user.service"

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  tokenUsage: string = "";
  homeHidden: boolean = true;
  mpHidden: boolean = true;
  manufacturerHidden: boolean = true;
  partsHidden: boolean = true;
  usersHidden: boolean = true;
  isHomeLoaded: boolean = false;
  isMpLoaded: boolean = false;
  isManufacturerLoaded: boolean = false;
  isPartsLoaded: boolean = false;
  isULoaded: boolean = false;
  menuStructure: Menu = { projectName: "Report Center", image: "fl-rc", isBreadcrumb: true };
  showHeader$: Observable<boolean>;
  reportsHidden = true;
  private subscriptions = new Subscription();

  constructor(
    @Inject(CS11_APP_ENVIRONMENT_EXTERNAL) private appEnvironmentExternal: string,
    public authorizationService: AuthorizationService,
    private translate: TranslateService,
    private hostService: HostService,
    private featuresService: FeaturesService,
    private meta: Meta,
    private activeBladeService: ActiveBladeService,
    private userIdleService: UserIdleService,
    private urlService: UrlService,
    private languageService: LanguageService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store,
    private appInsightsService: ApplicationInsightsService,
    private externalUserService: ExternalUserService
  ) {
    // TODO: find better way to load translations at start
    translate.getTranslation(languageService.getLanguage());
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang(languageService.getDefaultLanguage());
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use(languageService.getLanguage());
    translate.get("NavigationBar.Topic").subscribe((res: string) => {
      if (res) {
        this.menuStructure.projectName = res;
      }
    });

    if (this.appEnvironmentExternal !== "prod") {
      this.meta.addTag({ name: "robots", content: "noindex" });
    }

    this.subscriptions.add(
      router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === "primary"),
        mergeMap(route => route.data))
        .subscribe(event => {
          const target = event["target"];
          activeBladeService.setTarget(target);
          this.homeHidden = target !== Blade.Home;
          this.manufacturerHidden = target !== Blade.Manufacturer;
          this.partsHidden = target !== Blade.Parts;
          this.usersHidden = target !== Blade.Users;
          this.isHomeLoaded = this.isHomeLoaded || !this.homeHidden || environment.parallelReportLoader;
          this.isManufacturerLoaded = this.isManufacturerLoaded || !this.manufacturerHidden || environment.parallelReportLoader;
          this.isPartsLoaded = this.isPartsLoaded || !this.partsHidden || environment.parallelReportLoader;
          this.isULoaded = this.isULoaded || !this.usersHidden || environment.parallelReportLoader;
        })
    );

    this.showHeader$ = this.store.select(getHeaderState);

    this.subscriptions.add(
      this.userIdleService.isUserIdle$.subscribe(isUserIdle => this.reportsHidden = isUserIdle)
    );
  }

  ngOnInit() {
    this.userIdleService.start();
  }

  @HostListener("window:keydown")
  @HostListener("window:mousedown")
  @HostListener("window:mousemove")
  @HostListener("window:touchmove")
  @HostListener("window:wheel ")
  userMakeAction(): void {
    this.userIdleService.setUserActionPerformed();
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      this.authorizationService.isUserAuthorized$.pipe(
        tap(isUserAuthorized => this.userIdleService.enable(isUserAuthorized)),
        switchMap(isUserAuthorized => {
          if (!isUserAuthorized) {
            return [];
          }
          if (this.isTokenUsageVisible()) {
            return forkJoin([
              this.externalUserService.getExternalUser(),
              this.featuresService.getEmbedTrialFeature().pipe(take(1))
            ])
          }

          return forkJoin([
            this.externalUserService.getExternalUser()
          ]);
        })
      ).subscribe(([externalUser, embedTrial]) => {
        if (externalUser) {
          this.appInsightsService.appInsights?.trackEvent({ name: "userLoggedIn" }, {
            "isExternal": externalUser.isExternalUser
          });
        }
        if (this.isTokenUsageVisible()) {
          this.appInsightsService.appInsights?.trackEvent({ name: "handshake" }, {
            "nav-language": navigator.language,
            "nav-user-agent": navigator.userAgent,
            "app-version": environment.version
          });
          this.tokenUsage = `${embedTrial?.additionalInfo?.usage}%`;
        }
      })
    );
  }

  isTokenUsageVisible(): boolean {
    return this.hostService.isTopicEnvironment() || this.hostService.isLocalhostEnvironment();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
