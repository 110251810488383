import { Component, Input } from "@angular/core";

@Component({
  selector: "app-full-screen-message",
  standalone: true,
  imports: [],
  templateUrl: "./full-screen-message.component.html",
})
export class FullScreenMessageComponent {
  @Input() title?: string;
  @Input() message?: string;
  @Input() icon?: string;
}
