import { Injectable } from "@angular/core";
import { AlertService } from "@eplan/flux";
import { TranslateService } from "@ngx-translate/core";
import { Notification as FluxNotification, State } from "@eplan/flux";
import { BehaviorSubject } from "rxjs";

const MIN_TO_MS = 60 * 1000;
const IDLE_TIMEOUT_MS = 30 * MIN_TO_MS;
const IDLE_NOTIFY_TIMEOUT_MS = 25 * MIN_TO_MS;
const PBI_ACTIVITY_INTERVAL_MS = 1 * MIN_TO_MS;

@Injectable()
export class UserIdleService {
  refreshHandleActivity$ = new BehaviorSubject<boolean>(false);
  isUserIdle$ = new BehaviorSubject<boolean>(false);
  private timeoutId?: ReturnType<typeof setTimeout>;
  private notifyTimeoutId?: ReturnType<typeof setTimeout>;
  private activityIntervalId?: ReturnType<typeof setInterval>;
  private userIdleNotification?: FluxNotification | null;
  private userActionPerformed = false;
  private isEnabled = false;

  constructor(
    private alertService: AlertService,
    private translate: TranslateService,
  ) { }

  textToPrint() {
    return this.translate.instant("Alert.RemainingTime") + "\n" +
      this.translate.instant("Alert.ActionTake");
  }

  showUserIdleNotification(): void {
    if (!this.userIdleNotification) {
      this.userIdleNotification = new FluxNotification();
      this.userIdleNotification.message = this.textToPrint();
      this.userIdleNotification.type = State.WARNING;
      this.userIdleNotification.id = this.alertService.showNotification(this.userIdleNotification);
    }
  }

  hideUserIdleNotification(): void {
    if (this.userIdleNotification) {
      this.alertService.deleteNotification(this.userIdleNotification);
      this.userIdleNotification = null;
    }
  }

  setUserIdle(isIdle: boolean): void {
    if (this.isUserIdle$.value !== isIdle) {
      this.isUserIdle$.next(isIdle);
    }
    this.hideUserIdleNotification();
  }

  private checkActivityTimeOut() {
    clearTimeout(this.timeoutId);
    clearTimeout(this.notifyTimeoutId);

    this.timeoutId = setTimeout(() => {
      this.setUserIdle(true);
    }, IDLE_TIMEOUT_MS);

    this.notifyTimeoutId = setTimeout(() => {
      this.showUserIdleNotification();
    }, IDLE_NOTIFY_TIMEOUT_MS);
  }

  private startActivityInterval() {
    clearInterval(this.activityIntervalId);
    this.activityIntervalId = setInterval(() => {
      if (this.userActionPerformed) {
        this.refreshHandleActivity$.next(true);
        this.refreshHandleActivity$.next(false);
        this.userActionPerformed = false;
      }
    }, PBI_ACTIVITY_INTERVAL_MS);
  }

  enable(enable: boolean) {
    if (enable) {
      this.start();
    } else {
      this.stop();
    }
  }

  start() {
    if (!this.isEnabled) {
      this.checkActivityTimeOut();
      this.startActivityInterval();
      this.isEnabled = true;
    }
  }

  stop() {
    if (this.isEnabled) {
      clearTimeout(this.timeoutId);
      clearTimeout(this.notifyTimeoutId);
      clearInterval(this.activityIntervalId);
      this.isEnabled = false;
    }
  }

  setUserActionPerformed() {
    if (!this.isEnabled) {
      return;
    }

    this.userActionPerformed = true;

    if (this.isUserIdle$.value === true) {
      this.isUserIdle$.next(false);
    }

    this.setUserIdle(false);
    this.checkActivityTimeOut();
  }
}
